import React from 'react'
import style from './homepage.module.css'
import { t } from 'i18next'

const Howitwork = () => {
  return (
    <div className={style.Gridlove_section}>
    <div className={style.number_title} data-aos="flip-down" data-aos-duration="1000">
      <h1>{t('howitwork-1')}</h1>
    </div>
    <div className={style.main_grid_love} data-aos="fade-right" data-aos-duration="1000">
      <div className={style.left_girdlove}>

        <div className={style.circle_sidelove}>01</div>
        <div className={style.description_sidelove}>
          <h3>{t('howitwork-2')}</h3>
          <p>{t('howitwork-3')}</p>
        </div>

        <div className={style.circle_sidelove}>02</div>
        <div className={style.description_sidelove}>
          <h3>{t('howitwork-4')}</h3>
          <p>{t('howitwork-5')}</p>
        </div>

        <div className={style.circle_sidelove}>03</div>
        <div className={style.description_sidelove}>
          <h3>{t('howitwork-6')}</h3>
          <p>{t('howitwork-7')}</p>
        </div>

        <div className={style.circle_sidelove}>04</div>
        <div className={style.description_sidelove}>
          <h3>{t('howitwork-8')}</h3>
          <p>{t('howitwork-9')}</p>
        </div>

      </div>
    </div>
    <div className={style.right_sidelove} data-aos="fade-left" data-aos-duration="1000">
      <img src="asset/4step.png" />
    </div>
    <div style={{ clear: "both" }}></div>
  </div>

  )
}

export default Howitwork